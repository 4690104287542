import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import { Container, Row, Col } from "react-bootstrap";
// Images
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/seo";

const BikeStands = () => {
  return (
    <Layout>
      <Seo
        title="Soportes Aparcabicicletas - BIKESAFE"
        description="Nuestras soluciones de aparcamiento para bicicletas están adaptadas a sus necesidades individuales, ofreciendo precios competitivos y máxima calidad en el mercado."
      />
      <Container>
        <Row>
          <Col sm="12">
            <h1 className="mt-5 mb-5 text-center uppercase">
              Soportes Aparcabicicletas
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <p className="flow-text">
              Nuestras soluciones de aparcamiento para bicicletas se adaptan a
              sus necesidades individuales, ofreciendo precios competitivos y la
              máxima calidad del mercado.
            </p>
            <p className="flow-text">
              Explore nuestra gama de soportes aparcabicicletas a continuación:
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <hr />
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/99">
              <StaticImage
                placeholder="blurred"
                className="fluid"
                src="../product/99/homepage-99.jpg"
              ></StaticImage>
              <h3 className="uppercase mt-3">
                Soporta bici Salamanca tradicional galvanizado
              </h3>
              <p>
                El diseño clásico y popular es perfecto para aquellos que buscan
                una excelente relación calidad-precio, escaso mantenimiento y
                un...
              </p>
              <p className="shop-now">Solicitar cotización</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/100">
              <StaticImage
                placeholder="blurred"
                className="fluid"
                src="../product/100/homepage-100.jpg"
              ></StaticImage>
              <h3 className="uppercase mt-3">
                Soporte Bici Salamanca Tradicional
              </h3>
              <p>
                La forma permite que el cuadro y la rueda de la bicicleta se
                canden de forma segura al soporte. El diseño clásico y popular
                es...
              </p>
              <p className="shop-now">Solicitar cotización</p>
            </Link>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default BikeStands;
